/* spell-checker: ignore mmaaa */
import {
  CompiledFormat,
  CompiledAncillary,
  AncillaryTypeDocument,
  AuditLogAncillary
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import React from 'react';
import {Modal, ModalBody} from '@openstax/ui-components';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import { createRoute, makeScreen } from '../../core/services';
import { Layout } from '../../components/Layout';
import { useAncillaryApi } from '../../api/useAncillaryApi';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/fp/format';
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  fetchError,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { RouteLink } from "../../routing/components/RouteLink";
import { ancillaryEditScreen } from "./AncillaryEdit";
import { isFileValue } from "@project/lambdas/build/src/services/fileServer";
import { flow } from "@openstax/ts-utils";
import { FileViewLink } from "../../components/FileViewLink";
import { LinkButton } from "../../components/LinkButton";
import { isPlainObject } from "@openstax/ts-utils/guards";
import { Loader } from "@openstax/ui-components";
import { Html } from "../../components/Html";

const formatDate = flow(fromUnixTime, format('h:mmaaa MMM d yy'));

const Container = styled.div`
  display: flex;
  flex-direction: row;

  ol,ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
`;
const MainContent = styled.div`
  flex: 1;
  padding: 0.5rem;

  p {
    margin: 0;
    padding: 0;
  }
  dt {
    font-weight: bold;
    &:after {
      content: ': ';
    }
  }
  dd {
    margin: 0;
    padding: 0;
  }
  dd + dt {
    margin-top: 0.5rem;
  }
`;
const Sidebar = styled.div`
  max-width: 30rem;
  margin-left: 0.5rem;

  dl {
    padding: 0.5rem;

    dl {
      border-left: 2px solid #ccc;
    }
  }
  dt {
    display: inline;
    font-weight: bold;

    &:before {
      content: ' ';
      display: block;
    }
    &:after {
      content: ': ';
    }
  }

  dd {
    margin-left: 0;
    display: inline;
  }
`;

const useGetDocument = (id: string) => {
  const apiClient = useAncillaryApi();
  const [data, setData] = React.useState<FetchState<{
    ancillary: CompiledAncillary;
    ancillaryType: AncillaryTypeDocument;
  }, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0GetAncillary({params: {id: id}})
      .then(response => response.acceptStatus(200, 404))
      .then(response => response.status === 200 ? response.load() : undefined)
      .then(ancillary => ancillary !== undefined
        ? apiClient.apiV0GetAncillaryTypeDoc({params: {id: ancillary.type.id}})
          .then(response => response.acceptStatus(200, 404))
          .then(response => response.status === 200 ? response.load() : undefined)
          .then(ancillaryType => ancillaryType ? {ancillary, ancillaryType} : undefined)
        : undefined
      )
      .then(data => data !== undefined
        ? setData(fetchSuccess(data))
        : setData(previous => fetchError('item not found', previous))
      )
      .catch(() => setData(previous => fetchError('connection failed', previous)))
    ;
  }, [apiClient, id]);

  return data;
};

const RenderFormat = ({ancillaryId, data}: {ancillaryId: string; data: CompiledFormat}) => {
  return <>
    <dt><a
      target="_blank"
      href={data.url}
      rel="noreferrer"
    >{data.name}</a></dt>
    <dd>
      <dl>
        <dt>Type</dt>
        <dd>{data.type}</dd>
        <dt>Embeddable</dt>
        <dd>{data.embeddable ? 'Yes' : 'No'}</dd>
        <dt>Assignable</dt>
        <dd>{data.assignable ? 'Yes' : 'No'}</dd>
      </dl>
    </dd>
  </>;
};

const NewValue = styled.span`
  background-color: rgba(0,255,0,0.4);

  * {
   border-color: rgba(0,255,0,0.4) !important;
  }
`;
const OldValue = styled.span`
  background-color: rgba(255,0,0,0.4);
  text-decoration: line-through;

  * {
   border-color: rgba(255,0,0,0.4) !important;
  }
`;
const Diff = styled.dl`

`;

const ShowValue = ({data}: {data: any}) => {

  if (React.isValidElement(data)) {
    return data;
  }

  if (isPlainObject(data)) {
    return <dl>
      {Object.entries(data).map(([field, value]) => <React.Fragment key={field}>
        <dt>{field}</dt>
        <dd><ShowValue data={value} /></dd>
      </React.Fragment>)}
    </dl>;
  }

  if (data instanceof Array) {
    return <>
      {data.map((item, index) => <ShowValue key={index} data={item} />)}
    </>;
  }

  if (['string', 'number'].includes(typeof data)) {
    return data;
  }

  if (typeof data === 'boolean') {
    return data ? 'Yes' : 'No';
  }

  return null;
};

const DiffValue = ({head, base}: {head: any; base: any}) => {

  if ((head === undefined || isPlainObject(head)) && (base === undefined || isPlainObject(base))) {
    const fields = Object.keys({...head, ...base});
    const changes = fields
      .map(field => [field, head[field], base?.[field]] as const)
      .filter(([field,headValue,baseValue]) => !deepEqual(headValue, baseValue))
    ;

    if (changes.length === 0) {
      return <em>no changes</em>;
    }

    return <Diff>
      {changes.map(([field, headValue, baseValue]) => <React.Fragment key={field}>
        <dt>{field}</dt>
        <dd>
          <DiffValue head={headValue} base={baseValue} />
        </dd>
      </React.Fragment>)}
    </Diff>;
  }

  if (head instanceof Array && base instanceof Array) {
    const indexes = Array.from(Array(Math.max(head.length, base.length)).keys());

    const changes = indexes
      .map(index => [head[index], base[index]])
      .filter(([headValue, baseValue]) => !deepEqual(headValue, baseValue))
    ;

    return <>
      {changes.map(([headValue, baseValue], index) =>
        <DiffValue key={index} head={headValue} base={baseValue} />
      )}
    </>;
  }

  return <>
    {['string', 'number'].includes(typeof base)
      ? <OldValue>{base}</OldValue>
      : typeof base === 'boolean'
        ? <OldValue>{base ? 'Yes' : 'No'}</OldValue>
        : <OldValue><ShowValue data={base} /></OldValue>
    }
    {['string', 'number'].includes(typeof head)
      ? <NewValue>{head}</NewValue>
      : typeof head === 'boolean'
        ? <NewValue>{head ? 'Yes' : 'No'}</NewValue>
        : <NewValue><ShowValue data={head} /></NewValue>
    }
  </>;
};

const VersionDiff = ({head, base}: {head: AuditLogAncillary; base?: AuditLogAncillary}) => {

  return <>
    <header>
      {head.audit.author.type === 'user'
        ? `${head.audit.author.name} edited`
        : `${head.audit.author.type} change`
      } at {formatDate(head.audit.timestamp/1000)}
    </header>

    <DiffValue head={head.data} base={base?.data} />
  </>;
};

const AuditLog = ({id}: {id: string}) => {
  const apiClient = useAncillaryApi();
  const [{versions, hasMore}, setVersions] = React.useState<
    {versions: AuditLogAncillary[]; hasMore: boolean}
  >({versions: [], hasMore: false});
  const [diff, setDiff] = React.useState<undefined | {head: AuditLogAncillary; base?: AuditLogAncillary}>();

  React.useEffect(() => {
    apiClient.apiV0GetAncillaryAuditLog({params: {id}})
      .then(response => response.acceptStatus(200))
      .then(response => response.load())
      .then(response => setVersions({versions: response.items, hasMore: !!response.meta.nextPageToken}));
  }, [id, apiClient]);

  return <>
    <ol>
      {versions.slice(0, hasMore ? -1 : 5).map((version, index) => <li key={version.audit.timestamp}>
        <LinkButton onClick={() => setDiff({head: version, base: versions[index + 1]})} type="button">
          {version.audit.author.type === 'user' ? version.audit.author.name : version.audit.author.type}
        </LinkButton><br />
        <small>{formatDate(version.audit.timestamp/1000)}</small>
      </li>)}
    </ol>
    <Modal
      heading="Change Details"
      onModalClose={() => setDiff(undefined)}
      show={diff !== undefined}
    >
      <ModalBody style={{maxHeight: '50vh', overflow: 'auto'}}>
        {diff ? <VersionDiff {...diff} /> : null}
      </ModalBody>
    </Modal>
  </>;
};

const RenderAncillary = (props: {ancillary: CompiledAncillary; ancillaryType: AncillaryTypeDocument}) => {
  const {
    id, formats,
    defaultFormat, defaultAssignableFormat,
    tagLabels, tagLinks,
  } = props.ancillary;

  const mappedFields = React.useMemo(() => Object.fromEntries(props.ancillaryType.fields.map(fieldConfig => {
    const fieldValue = props.ancillary[fieldConfig.name as keyof CompiledAncillary];
    const fieldLabel = fieldConfig.name;

    if (fieldConfig.type === 'File') {
      return [
        fieldLabel,
        fieldValue === undefined
          ? undefined
          : isFileValue(fieldValue) ? <FileViewLink file={fieldValue} /> : 'invalid file'
      ];
    }
    if (['TextInput', 'TextArea'].includes(fieldConfig.type) && fieldConfig.markdown) {
      return [
        fieldLabel,
        fieldValue === undefined
          ? undefined
          : <Html block>{fieldValue}</Html>
      ];
    }

    return [fieldLabel, fieldValue];
  })), [props.ancillary, props.ancillaryType]);

  return <Container>
    <MainContent>
      <RouteLink route={ancillaryEditScreen} params={{id}}>edit</RouteLink>
      <ShowValue data={mappedFields} />
      <dl>
        <dt>default format</dt>
        <dd>{defaultFormat ? defaultFormat.name : <em>none</em>}</dd>
        <dt>default assignable format</dt>
        <dd>{defaultAssignableFormat ? defaultAssignableFormat.name : <em>none</em>}</dd>
      </dl>
      <h3>Tags</h3>
      <ul>
        {Object.entries(tagLabels).map(([tag, label]) => <li key={tag}>
          {tagLinks[tag]
            ? <a href={tagLinks[tag]} target="_blank" rel="noreferrer">{label}</a>
            : label
          }
        </li>)}
      </ul>
    </MainContent>
    <Sidebar>
      <h3>Updates</h3>
      <AuditLog id={id} />
      <h3>formats</h3>
      <dl>
        {formats.map((format) => <RenderFormat key={format.id} ancillaryId={id} data={format} />)}
      </dl>
    </Sidebar>
  </Container>;
};

export const AncillaryView = ({id}: {id: string}) => {
  const state = useGetDocument(id);

  return <Layout title={stateHasData(state) ? <Html>{state.data.ancillary.name}</Html> : ''}>
    {stateHasError(state) ? state.error : null}
    {stateHasData(state) ? <RenderAncillary {...state.data} /> : <Loader />}
  </Layout>;
};

export const ancillaryViewScreen = createRoute({name: 'AncillaryView', path: '/ancillaries/view/:id',
  handler: makeScreen(AncillaryView),
});
