import React from 'react';
import {
  AncillaryTypeDocument
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import styled from 'styled-components';
import * as Forms from '../../components/forms/controlled';
import { BuilderInputs, CustomFieldsConfig, File } from '../../components/inputs';
import { DynamicInput } from "../../components/forms/builder";
import { FetchState } from "@openstax/ts-utils/fetch";
import { AbstractFormData } from "../../components/forms/controlled/hooks";
import { ancillaryFormats, formatSetup } from "../formats";
import { isDefined } from "@openstax/ts-utils/guards";

export const PUBLICATION_STATES = ['published', 'draft', 'archived', 'deleted'];

const ancillaryDefaultFields = [
  {type: 'TextInput', name: 'name', label: 'Name', required: true, markdown: true, readOnlyFields: ['name']},
  {type: 'TextArea', name: 'description', label: 'Description', markdown: true, readOnlyFields: ['name']},
  {
    type: 'Select',
    name: 'publicationState',
    label: 'Publication State',
    options: PUBLICATION_STATES.map(option => ({label: option, value: option})),
    readOnlyFields: ['name', 'options']
  }
];

const FormRow = styled(Forms.FormSection)`
  display: flex;
  flex-direction: row;

  > *:not(:first-child) {
    margin-top: 0;
    margin-left: 5px;
  }
`;


type AncillaryTypeFormProps = {
  state: FetchState<AncillaryTypeDocument, string>;
  onSubmit: (data: AbstractFormData) => void;
  onCancel: () => void;
};

export const AncillaryTypeForm = ({onSubmit, state, onCancel}: AncillaryTypeFormProps) => {

  return <Forms.Form onSubmit={onSubmit} state={state} defaultData={{
    formats: [{}],
    fields: ancillaryDefaultFields
  }}>
    <Forms.Messages />
    <Forms.TextInput
      required
      name="name"
      label="Name"
      help={
        'Ancillary type names are shown when managing ancillaries and ancillary types, '
        + 'they are not visible to end users.'
      }
    />
    <Forms.TextArea
      required
      name="description"
      label="Description"
      help={
        'Ancillary type descriptions are shown when managing ancillaries and ancillary types, '
        + 'they are not visible to end users.'
      }
    />
    <Forms.TextArea
      name="instructions"
      label="Instructions"
      help="Additional instructions shown on the ancillary management screen."
    />
    <File
      name="icon"
      label="Icon"
      help="Icon to be displayed in assignments for this ancillary type."
    />
    <Forms.FormSection>
      <h3>Data Fields</h3>
      <h4>
        Additional data fields that may be input for ancillaries of this type.
        These fields can be used by related HTML or field templates.
      </h4>
      <CustomFieldsConfig
        name="fields"
        allowSort
        allowAdd
        requiredFieldNames={ancillaryDefaultFields.map(field => field.name)}
      />
    </Forms.FormSection>
    <Forms.FormSection>
      <h3>Delivery Formats</h3>
      <Forms.List name="formats">
        <Forms.ListItems>
          <Forms.FormSection
            style={{borderLeft: '2px solid #ccc', marginLeft: '0.5rem', paddingLeft: '0.5rem'}}
          >
            <FormRow>
              <Forms.Select
                required
                name="type"
                label="Format Type"
                options={[
                  {label: '', value: ''},
                  ...ancillaryFormats,
                ]}
              />
              <Forms.TextInput
                required
                wrapperProps={{style: {flex: 1}}}
                name="label"
                label="Form Heading"
              />
              <Forms.ListRecordRemoveButton>remove this format</Forms.ListRecordRemoveButton>
            </FormRow>
            <Forms.TextInput
              required
              name="displayName"
              label="Display Name"
              help="Shown when listing available formats to end users"
            />
            <Forms.GetFormValue name="type">
              {(type) => {
                const config = formatSetup[type as keyof typeof formatSetup];

                if (!config) {
                  return null;
                }

                return <>
                  {config.formatTypeFields.map(
                    ({type, ...props}) =>
                      <DynamicInput components={BuilderInputs} type={type} props={props as any} key={props.name} />
                  )}
                  {config.formatFields.length > 0 || config.allowCustomFormatFields
                    ? <CustomFieldsConfig name="fields"
                        label="The following fields will be asked for each ancillary"
                        allowAdd={config.allowCustomFormatFields}
                        requiredFieldNames={config.formatFields.map(field => field.name).filter(isDefined)}
                        {...config.allowCustomFormatFields
                          ? {
                            defaultFields: config.formatFields
                          }
                          : {
                            fields: config.formatFields
                          }
                        }
                      />
                    : null}
                </>;
              }}
            </Forms.GetFormValue>
            <Forms.Checkbox
              name="required"
              label="This format is required"
              help="Check this to require ancillaries of this type to configure this format."
            />
            <Forms.Checkbox
              name="embeddable"
              label="This format is embeddable"
              help={
                'Does this format work in an iframe? '
                + 'eg: pdfs are not embeddable because they download instead of displaying.'
              }
            />
            <Forms.GetFormValue name="embeddable">
              {(embeddable) => <Forms.Checkbox
                disabled={!embeddable}
                name="assignable"
                label="This format is assignable"
                help="Allow this format in Openstax Assignable. Assignable formats must be embeddable in an iframe."
              />}
            </Forms.GetFormValue>
          </Forms.FormSection>
        </Forms.ListItems>
        <Forms.ListRecordAddButton>add format</Forms.ListRecordAddButton>
      </Forms.List>
    </Forms.FormSection>
    <Forms.GetFormValue name="formats">
      {(formats) => <>
        <Forms.FormSection>
          <h3>Default Delivery Formats</h3>
          <Forms.Select
            name="defaultFormat"
            label="Default Format"
            help="Default format from listings and search results."
            options={
              (formats || [])
                .filter((format: any) => format.displayName)
                .map((format: any) => ({value: format.id, label: format.displayName}))
            }
          />
          <Forms.Select
            name="defaultAssignableFormat"
            label="Default Assignable Format"
            help="Default format to be used in Openstax Assignable."
            options={
              (formats || [])
                .filter((format: any) => format.displayName && format.assignable)
                .map((format: any) => ({value: format.id, label: format.displayName}))
            }
          />
        </Forms.FormSection>
      </>}
    </Forms.GetFormValue>
    <Forms.Buttons onCancel={onCancel} />
  </Forms.Form>;
};
