import React from 'react';
import styled from 'styled-components';
import * as Forms from '../../components/forms/controlled';
import {Inputs} from '../../components/inputs';
import { SelectAncillaryType } from "../../ancillary-types/components";
import {
  AncillaryDocument, AncillaryTypeDocument, FieldConfig
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import { DynamicInput } from "../../components/forms/builder";
import {Modal, ModalBody} from '@openstax/ui-components';
import { AbstractFormData } from "../../components/forms/controlled/hooks";
import { FetchState } from "@openstax/ts-utils/fetch";
import template from 'lodash/template';
import { LinkButton } from "../../components/LinkButton";
import { ActivityRelationSelect } from "./ActivitySubjectSelect";
import { Markdown } from "../../components/Markdown";

const FormRow = styled(Forms.FormSection)`
  display: flex;
  flex-direction: row;
`;
const FormMainContent = styled(Forms.FormSection)`
  flex: 1;
`;
const FormSidebar = styled(Forms.FormSection)`
  width: 200px;
  margin-left: 5px;
`;

type AncillaryFormProps = {
  state: FetchState<AncillaryDocument, string>;
  typeData?: AncillaryTypeDocument;
  onSubmit: (data: AbstractFormData) => void;
  onCancel: () => void;
};

export const AncillaryForm = ({onSubmit, state, typeData, onCancel}: AncillaryFormProps) => {
  const [ancillaryType, setAncillaryType] = React.useState<AncillaryTypeDocument | undefined>(typeData);
  const [formatModal, setFormatModal] = React.useState<string>();

  const compileData = (data: AbstractFormData, config: FieldConfig[]) => {
    const keyedConfig = config.reduce(
      (result, field) => ({...result, [field.id]: field}),
      {} as {[key: string]: FieldConfig}
    );
    const resolvedData = Object.fromEntries(Object.entries(data).map(
      ([id, value]) => [keyedConfig[id]?.name || id, value])
    );

    return resolvedData;
  };

  const compileForm = (data: AbstractFormData) => {
    const fields = ancillaryType?.fields && data.fields
      ? compileData(data.fields, ancillaryType.fields) : {};

    return {
      ...data,
      ...fields,
    };
  };

  const compileDefault = (data: AbstractFormData, defaultString?: string) => {
    if (!defaultString) {
      return undefined;
    }

    let result: string | undefined = undefined;
    try {
      result = template(defaultString)(data);
    } catch (e) {
      return undefined;
    }

    return result || undefined;
  };

  return <Forms.Form onSubmit={onSubmit} state={state}>
    <Forms.Messages />
      <Forms.GetFormData>
        {formData => <FormRow>
          <FormMainContent>
            <SelectAncillaryType
              required
              onChangeAncillaryType={setAncillaryType}
              name="type"
              label="Type"
            />
            {ancillaryType ? <>
              {ancillaryType.instructions
                ? <Forms.FormSection>
                  <h3>Instructions</h3>
                  <Markdown block>{ancillaryType.instructions}</Markdown>
                </Forms.FormSection>
                : null
              }
              <ActivityRelationSelect />
              <Forms.FormSection>
                <h3>Info</h3>
                <Forms.NameSpace name="fields">
                  {ancillaryType.fields ? ancillaryType.fields.map(({type, id, ...props}) => {
                      // computed defaults are filled in at read time. here we're just
                      // messaging it and making the input not required
                      const compiledFormData = compileForm(formData);
                      const computedDefault = compileDefault(
                        {...compiledFormData, form: compiledFormData},
                        props.default
                      );

                      return <DynamicInput key={id}
                        components={Inputs}
                        type={type as keyof typeof Inputs}
                        props={{
                          ...props,
                          name: id,
                          placeholder: computedDefault,
                          required: props.required && (
                            !computedDefault || (props.pattern && !computedDefault.match(props.pattern))
                          )
                        } as any}
                      />;
                    }) : null}
                </Forms.NameSpace>
              </Forms.FormSection>
            </>: null}
            <Forms.Buttons onCancel={onCancel} />
          </FormMainContent>
          <FormSidebar>
            <Forms.FormSection>
              maybe show previous edits here, empty on create
            </Forms.FormSection>
            <Forms.FormSection>
              <h4>Formats</h4>
              <Forms.NameSpace name="formats">
                {(ancillaryType?.formats || []).map((format) =>
                  <Forms.NameSpace name={format.id} key={format.id}>
                    <Forms.FormSection>
                      <LinkButton onClick={() => setFormatModal(format.id)} type="button">{format.label}</LinkButton>
                      <Modal
                        heading={format.label}
                        onModalClose={() => setFormatModal(undefined)}
                        show={formatModal === format.id}
                      >
                        <ModalBody>
                          <Forms.NameSpace name="fields">
                            <Forms.GetFormData>
                              {data => <>{format.fields.map(({type, ...props}) => {
                                // computed defaults are filled in at read time. here we're just
                                // messaging it and making the input not required
                                const form = compileForm(formData);
                                const computedDefault = 'default' in props
                                  ? compileDefault({data, form}, props.default)
                                  : undefined;

                                return <DynamicInput key={props.name}
                                  components={Inputs}
                                  type={type as keyof typeof Inputs}
                                  props={{
                                    ...props,
                                    name: props.id,
                                    placeholder: computedDefault,
                                    required: props.required && (
                                      !computedDefault || (
                                        'pattern' in props && props.pattern && !computedDefault.match(props.pattern)
                                      )
                                    )
                                  } as any}
                                />;
                              })}</>}
                            </Forms.GetFormData>
                          </Forms.NameSpace>
                        </ModalBody>
                      </Modal>

                    </Forms.FormSection>
                  </Forms.NameSpace>
                )}
              </Forms.NameSpace>
            </Forms.FormSection>
          </FormSidebar>
        </FormRow>}
      </Forms.GetFormData>
  </Forms.Form>;
};
