import { FileValue } from '@project/lambdas/build/src/services/fileServer';
import React from 'react';
import { useAncillaryApi } from '../api/useAncillaryApi';

export const FileViewLink = (props: {file: FileValue}) => {
  const apiClient = useAncillaryApi();
  const [fileViewUrl, setFileViewUrl] = React.useState<string>('');

  React.useEffect(() => {
    apiClient.apiV0AuthorizeFileView.renderUrl({query: {path: props.file.path}})
      .then(setFileViewUrl);
  }, [apiClient, props.file]);

  return <a href={fileViewUrl} target="_blank" rel="noreferrer">{props.file.label}</a>;
};
