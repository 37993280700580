import * as Controlled from './forms/controlled/inputTypes';
import * as Uncontrolled from './forms/uncontrolled';
import * as Forms from './forms/controlled';
import { DynamicInput, inputConfigs } from "./forms/builder";
import styled from 'styled-components';
import React from 'react';
import { useAncillaryApi } from "../api/useAncillaryApi";
import {
  FieldConfig
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import { useFormHelpers } from "./forms/controlled/hooks";
import {
  FileValue,
  FolderValue
} from "@project/lambdas/build/src/services/fileServer";
import {
  FetchStateType,
  fetchLoading,
  fetchIdle,
  fetchSuccess,
  FetchState,
  stateHasData
} from "@openstax/ts-utils/fetch";
import {search, AnyOrnLocateResponse} from '@openstax/orn-locator';
import {Loader} from '@openstax/ui-components';

type FileProps = {
};
export const File = (props: React.ComponentPropsWithoutRef<typeof Controlled.File> & FileProps) => {
  const apiClient = useAncillaryApi();

  const uploader = async(files: FileList | null) => {
    if (!files || files.length === 0) {
      return undefined;
    }
    const config = await apiClient.apiV0AuthorizeFileUpload({})
      .then(response => response.acceptStatus(200))
      .then(response => response.load())
    ;

    const file = files[0];

    const response: FileValue = {
      // s3 understands the `${filename}` placeholder, but we reconcile it here
      // so that we know the path to include with our form body
      // eslint-disable-next-line no-template-curly-in-string
      path: config.payload.key.replace('${filename}', file.name),
      label: file.name,
      mimeType: file.type,
      dataType: 'file',
    };

    const formData = new FormData();

    for (const [key, value] of Object.entries(config.payload)) {
      formData.append(key, value);
    }

    formData.append('file', file);

    await fetch(config.url, {
      method: 'POST',
      body: formData,
    });

    return response;
  };

  return <Controlled.File
    {...props}
    uploader={uploader}
  />;
};

type FolderProps = {
};
export const Folder = (props: React.ComponentPropsWithoutRef<typeof Controlled.File> & FolderProps) => {
  const apiClient = useAncillaryApi();

  const uploader = async(files: FileList | null) => {
    if (!files || files.length <= 0) {
      return undefined;
    }
    const config = await apiClient.apiV0AuthorizeFileUpload({})
      .then(response => response.acceptStatus(200))
      .then(response => response.load())
    ;

    const uploadFile = (file: File) => {
      const path = file.webkitRelativePath.split('/').splice(1).join('/');

      const response = {
        // s3 understands the `${filename}` placeholder, but we reconcile it here
        // so that we know the path to include with our form body
        // eslint-disable-next-line no-template-curly-in-string
        path: config.payload.key.replace('${filename}', path),
        label: path,
        mimeType: file.type,
        dataType: 'file' as const,
      };

      const formData = new FormData();

      // s3 doesn't respect the posted filename with directories, it strips off the filename
      // to use for the `${filename}` interpolation, so we have to override the key here
      // with the right thing.
      for (const [key, value] of Object.entries({...config.payload, key: response.path})) {
        formData.append(key, value);
      }

      // overwrite filename to strip the front directory
      formData.append('file', file, path);

      fetch(config.url, {
        method: 'POST',
        body: formData,
      });

      return response;
    };

    const response: FolderValue = {
      files: [],
      dataType: 'folder',
    };

    for(const file of Array.from(files)) {
      response.files.push(await uploadFile(file));
    }

    return response;
  };

  return <Controlled.File
    {...props}
    directory=""
    webkitdirectory=""
    multiple
    uploader={uploader}
  />;
};

export const Inputs = {
  ...Controlled,
  File,
  Folder,
};

type OrnSearchProps = {
  help?: string;
  wrapperProps?: React.ComponentPropsWithoutRef<'div'>;
  onSelect: (orn: string) => void;
};
export const OrnSearch = (props: OrnSearchProps) => {
  const [query, setQuery] = React.useState<string | undefined>();
  const [results, setResults] = React.useState<FetchState<Awaited<ReturnType<typeof search>>, string>>(fetchIdle());

  const handleSearch = React.useCallback(() => {
    if (query) {
      setResults(previous => fetchLoading(previous));

      // the search hangs the browser, so try to wait for the loader to
      // show up before triggering it
      setTimeout(() =>
        search(query).then(response =>
          setResults(fetchSuccess(response))
        )
      , 100);
    }
  }, [query]);

  return <Forms.FormSection {...props.wrapperProps}>
    <Uncontrolled.TextInput
      name="related"
      label="Subject Content"
      onChangeValue={setQuery} help={props.help}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          e.preventDefault();
          handleSearch();
        }
      }}
      addon={<button type="button" onClick={handleSearch}>search</button>}
    />

    {results.type === FetchStateType.LOADING
      ? <Loader />
      : null
    }

    {stateHasData(results) ? Object.entries(results.data).map(([type, {name, items}]) => <details key={name}>
      <summary>{name}</summary>
      <ul>
        {items.map((item: AnyOrnLocateResponse) => {
          const title = 'contextTitle' in item
            ? item.contextTitle : 'title' in item ? item.title
            : item.orn;

          return <li key={item.orn}>
            <button onClick={() => props.onSelect(item.orn)} type="button">add</button>
            {'urls' in item ? <a href={item.urls.main}>{title}</a> : title}
          </li>;
        })}
      </ul>
    </details>) : null}

  </Forms.FormSection>;
};

const FormRow = styled(Forms.FormSection)`
  display: flex;
  flex-direction: row;

  > *:not(:first-child) {
    margin-top: 0;
    margin-left: 5px;
  }
`;
export const CustomFieldsConfig = (
  props: {
    requiredFieldNames?: string[];
    allowSort?: boolean;
    allowAdd?: boolean;
    label?: string;
    name: string;
    defaultFields?: Omit<FieldConfig, 'id'>[];
    fields?: Omit<FieldConfig, 'id'>[];
  }
) => {
  const {data, setInput} = useFormHelpers();
  const thisField = data[props.name];

  const setInputRef = React.useRef(setInput);
  setInputRef.current = setInput;

  const thisFieldRef = React.useRef(thisField);
  thisFieldRef.current = thisField;

  const fields = React.useMemo(() => {
    if (!props.fields) {
      return undefined;
    }
    return props.fields.map((configuredField): FieldConfig => {
      const existingField = thisFieldRef.current?.find(
        (search: FieldConfig) => search.name === configuredField.name
      );
      return {...configuredField, ...existingField};
    });
  }, [props.fields]);

  const previousFieldsRef = React.useRef(fields);

  React.useEffect(() => {
    if (fields || previousFieldsRef.current) {
      setInputRef.current.field(props.name)(fields);
    }
  }, [props.name, fields]);

  React.useEffect(() => {
    if (thisField === undefined && props.defaultFields) {
      setInput.field(props.name)(props.defaultFields);
    }
  }, [thisField, props.name, setInput, props.defaultFields]);

  React.useEffect(() => {
    previousFieldsRef.current = fields;
  }, [fields]);

  return <Forms.List name={props.name}>
    {props.label ? <p>{props.label}</p> : null}
    <Forms.ListItems>
      <FormRow
        style={{borderLeft: '2px solid #ccc', marginLeft: '0.5rem', paddingLeft: '0.5rem'}}
      >
        {props.allowSort !== false && thisField instanceof Array && thisField.length > 1
          ? <Forms.ListRecordSortableHandle style={{width: '1rem', height: 'auto'}} />
          : null
        }
        <Forms.FormSection style={{flex:1}}>
          <Forms.GetFormData>
            {({readOnlyFields}) => readOnlyFields?.includes('name')
              ? <>
                <Forms.TextInput
                  required
                  name="label"
                  label="Form Label"
                />
                <Forms.TextInput
                  readOnly={true}
                  name="name"
                  label="Name"
                  help="This field name cannot be changed."
                />
                <Forms.TextInput
                  name="help"
                  label="Help Text"
                />
                <Forms.GetFormValue name="type">
                  {(selectedType) => <>
                    {(inputConfigs[selectedType as keyof typeof inputConfigs] || [])
                      .map(({type, ...props}, index) => <DynamicInput
                        key={index}
                        components={Inputs}
                        type={type}
                        props={{...props, ...(readOnlyFields.includes(props.name) && {readOnly: true})}}
                      />)
                    }
                  </>}
                </Forms.GetFormValue>
              </>
              : <>
                <FormRow>
                  <Forms.Select
                    required
                    name="type"
                    label="Input Type"
                    options={[
                      {label: '', value: ''},
                      ...Object.keys(Inputs).map(type => ({label: type, value: type}))
                    ]}
                  />
                  <Forms.TextInput
                    required
                    wrapperProps={{style: {flex: 1}}}
                    name="label"
                    label="Form Label"
                  />
                  <Forms.ListRecordRemoveButton>remove this field</Forms.ListRecordRemoveButton>
                </FormRow>
                <Forms.TextInput
                  required
                  name="name"
                  label="Name"
                  help="internal name used in templates and api integrations"
                />
                <Forms.TextInput
                  name="help"
                  label="Help Text"
                />
                <Forms.GetFormValue name="type">
                  {(selectedType) => <>
                    {(inputConfigs[selectedType as keyof typeof inputConfigs] || [])
                      .map(({type, ...props}, index) => <DynamicInput
                        key={index}
                        components={Inputs}
                        type={type}
                        props={props}
                      />)
                    }
                  </>}
                </Forms.GetFormValue>
                <Forms.Checkbox
                  name="required"
                  label="This field is required"
                  help="Check this to require ancillaries of this type to configure this field."
                />
              </>
            }
          </Forms.GetFormData>
        </Forms.FormSection>
      </FormRow>
    </Forms.ListItems>
    {props.allowAdd === true
      ? <Forms.ListRecordAddButton>add field</Forms.ListRecordAddButton>
      : null
    }
  </Forms.List>;
};

export const BuilderInputs = {
  ...Inputs,
  CustomFieldsConfig,
};
