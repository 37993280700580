import React from 'react';
import { UnauthorizedError } from '@openstax/ts-utils/errors';
import styled from 'styled-components';
import { ancillaryListScreen } from '../ancillaries/screens/AncillaryList';
import { RouteLink } from "../routing/components/RouteLink";
import { ancillaryTypeListScreen } from "../ancillary-types/screens/AncillaryTypeList";
import {NavBar} from '@openstax/ui-components';
import { useUserRoles } from "../auth/useAuth";
import { FetchStateType } from "@openstax/ts-utils/fetch";
import * as UI from "@openstax/ui-components";

interface Props {
  title: string | React.ReactNode;
}

const Links = styled.div`

  a {
    &, &:visited {
      color: #333;

      &:hover {
        color: #555;
      }
    }

    &, &:hover {
      text-decoration: none;
    }

    &:not(:first-child) {
      padding-left: 0.5rem;
      margin-left: 0.5rem;
      border-left: 1px solid #eee;
    }
  }
`;

export const Layout = (props: React.PropsWithChildren<Props>) => {
  const roles = useUserRoles();

  if (roles.type !== FetchStateType.SUCCESS) {
    return <UI.Loader />;
  }

  if (roles.data.length === 0) {
    throw new UnauthorizedError();
  }

  return <div>
    <NavBar logo>
      <Links>
        <RouteLink route={ancillaryListScreen}>Ancillaries</RouteLink>
        {roles.data.some(r => ['admin', 'content-admin'].includes(r))
          ? <RouteLink route={ancillaryTypeListScreen}>Ancillary types</RouteLink>
          : null
        }
      </Links>
    </NavBar>
    <h1>{props.title}</h1>
    {props.children}
  </div>;
};
