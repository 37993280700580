import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { Layout } from "../../components/Layout";
import { useCreateTypeFormState } from "./useCreateTypeFormState";
import { useServices } from "../../core/context/services";
import { AncillaryTypeForm } from "../components/AncillaryTypeForm";

export const AncillaryTypeCreate = () => {
  const services = useServices();
  const {state, onSubmit} = useCreateTypeFormState();

  return <Layout title="Create New Ancillary Type">
    <AncillaryTypeForm
      state={state}
      onSubmit={onSubmit}
      onCancel={() => services.history.back.bind(services.history)}
    />
  </Layout>;
};

export const ancillaryTypeCreateScreen = createRoute({name: 'AncillaryTypeCreate', path: '/ancillary-types/create',
  handler: makeScreen(AncillaryTypeCreate)
});
