import React from 'react';
import { useAncillaryApi } from "../api/useAncillaryApi";
import {
  fetchError,
  fetchLoading,
  FetchState,
  fetchSuccess,
} from "@openstax/ts-utils/fetch";
import { AncillaryApiClient } from '../api/ancillaryApiMiddleware';
import { useSetAppError } from '@openstax/ui-components';

export type SearchData = Awaited<ReturnType<typeof executeSearch>>;

export type SearchOptions = {
  page?: string;
};

const executeSearch = (apiClient: AncillaryApiClient, options: SearchOptions) => {
  return apiClient.apiV0SearchAncillaryTypeDocs({query: options})
    .then(response => response.acceptStatus(200).load());
};

export const useSearchAncillaryTypes = (options: SearchOptions) => {
  const apiClient = useAncillaryApi();
  const setAppError = useSetAppError();
  const [pageData, setPageData] = React.useState<FetchState<SearchData, string>>(fetchLoading());
  
  React.useEffect(() => {
    executeSearch(apiClient, options)
      .then(data => setPageData(fetchSuccess(data)))
      .catch(e => {
        setPageData(previous => fetchError('connection failed', previous));
        setAppError(e);
      })
    ;
  }, [apiClient, options, setAppError]);

  return pageData;
};
