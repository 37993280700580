import {
  FieldConfig
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";

type FormatSetup = {
  allowCustomFormatFields: boolean;
  formatTypeFields: Omit<FieldConfig, 'id'>[];
  formatFields: Omit<FieldConfig, 'id'>[];
};

export const formatSetup = {
  url: {
    allowCustomFormatFields: false,
    formatTypeFields: [
    ],
    formatFields: [
      {
        type: 'TextInput' as const,
        name: 'url',
        label: 'Url',
        required: true,
      }
    ],
  } as FormatSetup,
  template: {
    allowCustomFormatFields: true,
    formatTypeFields: [
      {
        type: 'Folder' as const,
        name: 'template',
        label: 'Upload Html Template',
        required: true,
        help: 'Select a folder with an index.html in it. only the index.html will be interpolated, ' + 
          'but supporting assets can be provided in the directory.'
      },
    ],
    formatFields: [
    ],
  } as FormatSetup,
  file: {
    allowCustomFormatFields: false,
    formatTypeFields: [
    ],
    formatFields: [
      {
        type: 'File' as const,
        name: 'file',
        label: 'Upload File',
        required: true,
      }
    ],
  } as FormatSetup,
  folder: {
    allowCustomFormatFields: false,
    formatTypeFields: [
    ],
    formatFields: [
      {
        type: 'Folder' as const,
        name: 'folder',
        label: 'Upload Folder',
        required: true,
      }
    ],
  } as FormatSetup,
};

export const ancillaryFormats = [
  {
    label: 'Url',
    value: 'url'
  },
  {
    label: 'HTML Template',
    value: 'template'
  },
  {
    label: 'HTML Package',
    value: 'folder'
  },
  {
    label: 'File Download',
    value: 'file'
  }
];
