import { v4 as uuid } from "uuid";
import React from 'react';
import { AncillaryApiClient } from "../../api/ancillaryApiMiddleware";
import { useAncillaryApi } from "../../api/useAncillaryApi";
import {
  AncillaryDocument
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import {
  FetchState, fetchIdle, fetchLoading, FetchStateType, fetchSuccess, fetchError
} from "@openstax/ts-utils/fetch";
import { useServices } from '../../core/context/services';
import { ancillaryViewScreen } from "./AncillaryView";
import { renderRouteUrl } from "../../core";
import { AbstractFormData } from "../../components/forms/controlled";

type Payload = Parameters<AncillaryApiClient['apiV0WriteAncillaryDoc']>[0]['payload'];

export const useSaveNewAncillary = () => {
  const [state, setState] = React.useState<FetchState<AncillaryDocument, string>>(fetchIdle());
  const apiClient = useAncillaryApi();

  const handler = React.useCallback((payload: Payload) => {
    setState(previous => fetchLoading(previous));
    const id = uuid();

    return apiClient.apiV0WriteAncillaryDoc({params: {id}, payload})
      .then(response => response.acceptStatus(201))
      .then(response => response.load())
      .then(data => setState(fetchSuccess(data)))
      .catch(() => setState(previous => fetchError('there was an error saving this document', previous)))
    ;
  }, [apiClient]);

  return [handler, state] as const;
};

export const useCreateFormState = () => {
  const [saveNew, state] = useSaveNewAncillary();
  const services = useServices();

  const onSubmit = React.useCallback((data: AbstractFormData) => {
    return saveNew(data as Payload);
  }, [saveNew]);

  React.useEffect(() => {
    if (state.type === FetchStateType.SUCCESS) {
      services.history.push(renderRouteUrl(ancillaryViewScreen, {id: state.data.id}));
    }
  }, [state, services.history]);

  return {state, onSubmit};
};

