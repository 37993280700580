import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { useQuery } from "../../routing/useQuery";
import { Layout } from "../../components/Layout";
import {
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { RouteLink } from '../../routing/components/RouteLink';
import { SearchData, SearchOptions, useSearchAncillaryTypes } from "../hooks";
import { ancillaryTypeCreateScreen } from './AncillaryTypeCreate';
import { ancillaryTypeEditScreen } from "./AncillaryTypeEdit";

const SearchResults = ({searchData, options}: {searchData: SearchData; options: SearchOptions}) => {
  const {currentPage, totalPages} = searchData.meta;

  return <div>
    <ol>
      {searchData.items.map(item => <li key={item.id}>
        <RouteLink route={ancillaryTypeEditScreen} params={{id: item.id}}>{item.name}</RouteLink>
      </li>)}
    </ol>
    {currentPage > 1
      ? <RouteLink
        route={ancillaryTypeListScreen}
        query={{...options, page: String(currentPage - 1)}}
      >prev page</RouteLink>
      : null
    }
      {currentPage < totalPages
      ? <RouteLink
        route={ancillaryTypeListScreen}
        query={{...options, page: String(currentPage + 1)}}
      >next page</RouteLink>
      : null
    }
  </div>;
};

export const AncillaryTypeList = () => {
  const options = useQuery();
  const searchState = useSearchAncillaryTypes(options);

  return <Layout title="Ancillary Type List">
    <RouteLink route={ancillaryTypeCreateScreen}>create new</RouteLink>
    {stateHasError(searchState) ? searchState.error : null}
    {stateHasData(searchState) ? <SearchResults searchData={searchState.data} options={options}/> : null}
  </Layout>;
};

export const ancillaryTypeListScreen = createRoute({name: 'AncillaryTypeList', path: '/ancillary-types',
  handler: makeScreen(AncillaryTypeList)
});
