import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { Layout } from "../../components/Layout";
import { useCreateFormState } from "./useCreateFormState";
import { useServices } from "../../core/context/services";
import { AncillaryForm } from "../components/AncillaryForm";

export const AncillaryCreate = () => {
  const services = useServices();
  const {state, onSubmit} = useCreateFormState();

  return <Layout title="Create New Ancillary">
    <AncillaryForm
      onSubmit={onSubmit}
      state={state}
      onCancel={() => services.history.back.bind(services.history)}
    />
  </Layout>;
};

export const ancillaryCreateScreen = createRoute({name: 'AncillaryCreate', path: '/ancillaries/create',
  handler: makeScreen(AncillaryCreate)
});
