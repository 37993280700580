import React from 'react';
import { AncillaryApiClient } from "../../api/ancillaryApiMiddleware";
import { useAncillaryApi } from "../../api/useAncillaryApi";
import {
  AncillaryTypeDocument
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";
import {
  FetchState, fetchLoading, FetchStateType, fetchSuccess, fetchError
} from "@openstax/ts-utils/fetch";
import { useServices } from '../../core/context/services';
import { AbstractFormData } from "../../components/forms/controlled";

type Payload = Parameters<AncillaryApiClient['apiV0WriteAncillaryTypeDoc']>[0]['payload'];

export const useEditAncillaryType = (id: string) => {
  const [state, setState] = React.useState<FetchState<AncillaryTypeDocument, string>>(fetchLoading());
  const apiClient = useAncillaryApi();

  React.useEffect(() => {
    apiClient.apiV0GetAncillaryTypeDoc({params: {id}})
      .then(response => response.acceptStatus(200))
      .then(response => response.load())
      .then(data => setState(fetchSuccess(data)))
      .catch(() => setState(previous => fetchError('there was an error saving this document', previous)))
    ;
  }, [apiClient, id]);

  const handler = React.useCallback((payload: Payload) => {
    setState(previous => fetchLoading(previous));

    return apiClient.apiV0WriteAncillaryTypeDoc({params: {id}, payload})
      .then(response => response.acceptStatus(201))
      .then(response => response.load())
      .then(data => setState(fetchSuccess(data)))
      .catch(() => setState(previous => fetchError('there was an error saving this document', previous)))
    ;
  }, [apiClient, id]);

  return [handler, state] as const;
};

export const useEditTypeFormState = (id: string) => {
  const [save, state] = useEditAncillaryType(id);
  const services = useServices();

  const onSubmit = React.useCallback((data: AbstractFormData) => {
    return save(data as Payload);
  }, [save]);

  const stateEstablishedRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (state.type === FetchStateType.SUCCESS && !stateEstablishedRef.current) {
      stateEstablishedRef.current = true;
    } else if (state.type === FetchStateType.SUCCESS) {
      // TODO - view page
      services.history.push('/');
    }
  }, [state, services.history]);


  return {state, onSubmit};
};

