import { ancillaryCreateScreen } from './AncillaryCreate';
import { ancillaryListScreen } from './AncillaryList';
import { ancillaryViewScreen } from './AncillaryView';
import { ancillaryEditScreen } from "./AncillaryEdit";

export const ancillaryRoutes = () => ([
  ancillaryListScreen,
  ancillaryEditScreen,
  ancillaryViewScreen,
  ancillaryCreateScreen,
]);
