"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFileOrFolder = exports.isFolderValue = exports.isFileValue = void 0;
const guards_1 = require("@openstax/ts-utils/guards");
const isFileValue = (thing) => (0, guards_1.isPlainObject)(thing)
    && Object.keys(thing).every(key => ['dataType', 'path', 'label', 'mimeType'].includes(key))
    && thing.dataType === 'file'
    && typeof thing.mimeType === 'string'
    && typeof thing.path === 'string'
    && typeof thing.label === 'string';
exports.isFileValue = isFileValue;
const isFolderValue = (thing) => (0, guards_1.isPlainObject)(thing)
    && Object.keys(thing).every(key => ['dataType', 'files'].includes(key))
    && thing.dataType === 'folder'
    && thing.files instanceof Array
    && thing.files.every(exports.isFileValue);
exports.isFolderValue = isFolderValue;
const isFileOrFolder = (thing) => (0, exports.isFileValue)(thing) || (0, exports.isFolderValue)(thing);
exports.isFileOrFolder = isFileOrFolder;
