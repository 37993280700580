import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { Layout } from "../../components/Layout";
import { useEditTypeFormState } from "./useEditTypeFormState";
import { useServices } from "../../core/context/services";
import { AncillaryTypeForm } from "../components/AncillaryTypeForm";
import {Loader} from '@openstax/ui-components';
import { stateHasData } from "@openstax/ts-utils/fetch";

export const AncillaryTypeEdit = ({id}: {id: string}) => {
  const services = useServices();
  const {state, onSubmit} = useEditTypeFormState(id);

  return <Layout title="Edit Ancillary Type">
    {stateHasData(state)
      ?  <AncillaryTypeForm
        state={state}
        onSubmit={onSubmit}
        onCancel={() => services.history.back.bind(services.history)}
      />
      : <Loader />
    }
  </Layout>;
};

export const ancillaryTypeEditScreen = createRoute({name: 'AncillaryTypeEdit', path: '/ancillary-types/edit/:id',
  handler: makeScreen(AncillaryTypeEdit)
});
