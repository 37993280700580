"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resourceTypeLabels = exports.resourceTypes = exports.supportTypes = exports.activityTypes = void 0;
exports.activityTypes = [
    { label: 'Primer', value: 'primer', group: 'Learning Activity' },
    { label: 'Preparedness', value: 'preparedness', group: 'Learning Activity' },
    { label: 'Learning', value: 'learning', group: 'Learning Activity' },
    { label: 'Practice', value: 'practice', group: 'Learning Activity' },
    { label: 'Enrichment', value: 'enrichment', group: 'Learning Activity' },
    { label: 'Assessment', value: 'assessment', group: 'Learning Activity' },
    { label: 'Reflection', value: 'reflection', group: 'Learning Activity' },
    { label: 'Review', value: 'review', group: 'Learning Activity' },
];
exports.supportTypes = [
    { label: 'Support', value: 'support', group: 'Support Resource' },
];
exports.resourceTypes = [
    ...exports.supportTypes,
    ...exports.activityTypes,
];
exports.resourceTypeLabels = Object.fromEntries(exports.resourceTypes.map(({ label, value }) => [value, label]));
