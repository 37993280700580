/* eslint-disable import/no-webpack-loader-syntax */
import type {TRoutes as ApiRoutes} from '@project/lambdas/build/src/functions/serviceApi/core/routes';
// @ts-ignore-next-line
import routes from '!!val-loader!@project/lambdas/build/script/utils/routeDataLoader';
import { AppServices } from "../core/types";
import {assertDefined} from "@openstax/ts-utils/assertions";

const config = {
  apiBase: () => process.env.NODE_ENV === 'production'
    ? assertDefined(process.env.REACT_APP_API_BASE_URL, 'REACT_APP_API_BASE_URL must be provided in production')
    : '/'
};

export const createAncillaryApi = (app: AppServices) => {
  return app.createApiGateway<ApiRoutes>(config, routes);
};

export type AncillaryApiClient = ReturnType<typeof createAncillaryApi>;
