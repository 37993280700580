import React from 'react';
import { Select } from "../../components/forms/controlled/inputTypes";
import { useSearchAncillaryTypes, SearchOptions } from "../hooks";
import { stateHasData } from "@openstax/ts-utils/fetch";
import {
  AncillaryTypeDocument
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/types";

type SelectAncillaryTypeProps = Omit<React.ComponentPropsWithoutRef<typeof Select>, 'options'> & {
  onChangeAncillaryType?: (type: AncillaryTypeDocument | undefined) => void;
};
export const SelectAncillaryType = ({onChangeAncillaryType, ...props}: SelectAncillaryTypeProps) => {
  const [options] = React.useState<SearchOptions>({});
  const state = useSearchAncillaryTypes(options);

  const optionsProp = React.useMemo(() => [
    {value: '', label: ''},
    ...(stateHasData(state)
      ? state.data.items.map((type: any) => ({value: type.id, label: type.name}))
      : []
    )
  ], [state]);

  return <Select
    onChangeValue={id => {
      onChangeAncillaryType?.(stateHasData(state) ? state.data.items.find((type: any) => type.id === id) : undefined);
      props.onChangeValue?.(id);
    }}
    options={optionsProp}
    {...props}
  />;
    
};
