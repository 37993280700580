/* spell-checker: ignore dompurify */
import React from 'react';
import { assertString } from "@openstax/ts-utils/assertions";
import DOMPurify from 'dompurify';

export const Html = (props: React.PropsWithChildren<{block?: boolean}>) => {
  if (props.children === undefined) {
    return null;
  }
  const html = DOMPurify.sanitize(assertString(props.children));
  return props.block
    ? <div dangerouslySetInnerHTML={{__html: html}} />
    : <span dangerouslySetInnerHTML={{__html: html}} />
  ;
};
