import React from 'react';
import styled from 'styled-components';
import * as Forms from '../../components/forms/controlled';
import * as Uncontrolled from '../../components/forms/uncontrolled';
import { OrnSearch } from "../../components/inputs";
import { useFormHelpers, useFormListHelpers } from "../../components/forms/controlled/hooks";
import { FetchState, stateHasData, fetchSuccess, fetchLoading, FetchStateType } from "@openstax/ts-utils/fetch";
import { AnyOrnLocateResponse, locate } from "@openstax/orn-locator";
import {
  resourceTypes
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/ancillary/routes/activityTypes";

const FormRow = styled(Forms.FormSection)`
  display: flex;
  flex-direction: row;

  > *:not(:first-child) {
    margin-top: 0;
    margin-left: 5px;
  }
`;

const SubjectInfo = () => {
  const {data} = useFormHelpers();
  const [resourceState, setResourceState] = React.useState<FetchState<AnyOrnLocateResponse, string>>(fetchLoading());

  React.useEffect(() => {
    locate(data.orn).then(result => setResourceState(fetchSuccess(result)));
  }, [data]);

  if (resourceState.type === FetchStateType.LOADING) {
    return <span>'resolving...'</span>;
  }
  if (!stateHasData(resourceState)) {
    return <span>error loading resource "{data.type}: {data.orn}"</span>;
  }

  const resource = resourceState.data;

  const title = 'contextTitle' in resource
    ? resource.contextTitle : 'title' in resource ? resource.title
    : resource.orn;

  return <span style={{flex: 1}}>
    {data.type}:{' '}{title}
  </span>;
};

const AddSubject = () => {
  const listState = useFormListHelpers();
  const [type, setType] = React.useState<string>('practice');

  return <FormRow>
    <Uncontrolled.Select
      required
      name="type"
      label="Resource Type"
      value={type}
      onChangeValue={setType}
      options={resourceTypes}
    />
    <OrnSearch
      onSelect={(orn) => listState.addRecord({orn, type})}
      wrapperProps={{style: {flex: 1}}}
      help="Search for a book, page, or other content that this ancillary is about."
    />
  </FormRow>;
};

type ActivitySubjectSelectProps = {
};
export const ActivityRelationSelect =  (props: ActivitySubjectSelectProps) => {
  return <Forms.FormSection>
    <h3>Related Content</h3>
    <p>
      Listing content that this resource relates to (and how it relates to it)
      helps make it more discoverable. The related content can be
      book content (book, section, element) or other types of content, even
      other ancillaries.
    </p>
    <p>
      Ancillaries can be <em>Learning Activities</em> or <em>Support Resources</em>. Currently
      {' '}<em>Support Resources</em> are only exposed in relation to a <strong>book</strong>
      {' '}or <strong>library</strong>
    </p>
    <Forms.List name="relations">
      <Forms.ListItems>
        <FormRow
          style={{borderLeft: '2px solid #ccc', marginLeft: '0.5rem', paddingLeft: '0.5rem'}}
        >
          <SubjectInfo />
          <Forms.ListRecordRemoveButton>remove</Forms.ListRecordRemoveButton>
        </FormRow>
      </Forms.ListItems>
      <AddSubject />
    </Forms.List>
  </Forms.FormSection>;
};
