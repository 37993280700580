import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { Layout } from "../../components/Layout";
import { useEditFormState } from "./useEditFormState";
import { useServices } from "../../core/context/services";
import { AncillaryForm } from "../components/AncillaryForm";
import { stateHasData } from "@openstax/ts-utils/fetch";
import {Loader} from '@openstax/ui-components';

export const AncillaryEdit = ({id}: {id: string}) => {
  const services = useServices();
  const {state, onSubmit} = useEditFormState(id);

  return <Layout title="Edit Ancillary">
    {stateHasData(state)
      ?  <AncillaryForm
        state={state}
        onSubmit={onSubmit}
        onCancel={() => services.history.back.bind(services.history)}
      />
      : <Loader />
    }
  </Layout>;
};

export const ancillaryEditScreen = createRoute({name: 'AncillaryEdit', path: '/ancillaries/edit/:id',
  handler: makeScreen(AncillaryEdit)
});
