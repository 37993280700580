import { marked } from 'marked';
import React from 'react';
import { assertString } from "@openstax/ts-utils/assertions";

export const Markdown = (props: React.PropsWithChildren<{block?: boolean}>) => {
  const text = assertString(props.children);
  const html = React.useMemo(() => marked.parse(text), [text]);
  return props.block
    ? <div dangerouslySetInnerHTML={{__html: html}} />
    : <span dangerouslySetInnerHTML={{__html: html}} />
  ;
};
